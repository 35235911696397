import {Resource} from '../../../../shared/models/resource/resource.model';

export class Liability {
  id?: number;
  amount?: number;
  liabilityType?: Resource;
  periodicity?: Resource;
  takeover?: Resource;
  contractNumber?: string;
  creditProvider?: Resource;
  duration?: number;
  initialAmount?: number;
  interestRate?: number;
  regularity?: Resource;
  startDate?: string;
  goneAfterRequest?: boolean;
  endDate?: string;
  balance?: number;
  monthlyAmount?: number;
  earlyRepaymentFee?: number;
  irregularityStartDate?: Date;
  handlingCosts: number;
  deedDate?: Date;
  fiscalAttestationAmount?: number;
	fiscalAttestationFactor?: number;
  immovableAmount?: number;
  nature?: string;
  remainingDuration?: number;
  repayableAmount?: number;
  aprc?: number;
  

  constructor(data?: any) {
    if (!(data === undefined || data === null)) {
      this.id = data.id || null;
      this.amount = data.amount || 0;
      this.liabilityType = Resource.createResource(data.liabilityType);
      this.periodicity = Resource.createResource(data.periodicity);
      this.takeover = Resource.createResource(data.takeover);
      this.contractNumber = data.contractNumber || '';
      this.creditProvider = Resource.createResource(data.creditProvider);
      this.duration = data.duration || 0;
      this.initialAmount = data.initialAmount || 0;
      this.interestRate = data.interestRate || 0;
      this.regularity = Resource.createResource(data.regularity);
      this.startDate = data.startDate || '';
      if (!(data.goneAfterRequest === undefined || data.goneAfterRequest === null)) {
        this.goneAfterRequest = data.goneAfterRequest;
      }
      this.endDate = data.endDate || '';
      this.balance = data.balance || 0;
      this.monthlyAmount = data.monthlyAmount || 0;
      this.irregularityStartDate = data.irregularityStartDate || null;
      this.earlyRepaymentFee = data.earlyRepaymentFee === 0 ? 0 : data.earlyRepaymentFee || null;
      this.handlingCosts = data.handlingCosts || 0;
      this.deedDate = data.deedDate || null;
      this.fiscalAttestationAmount = data.fiscalAttestationAmount || null;
			this.fiscalAttestationFactor = data.fiscalAttestationFactor || 0;
      this.immovableAmount = data.immovableAmount === 0 ? 0 : data.immovableAmount || null;
      this.nature = data.nature || '';
      this.remainingDuration = data.remainingDuration === 0 ? 0 : data.remainingDuration || 0;
      this.repayableAmount = data.repayableAmount === 0 ? 0 : data.repayableAmount || 0;
      this.aprc = data.aprc || 0;
    }
  }
}

export class FiscalAttestation {
  initialAmount?: number;
  fiscalAttestationAmount?: number;
  fiscalAttestationFactor?: number;
  immovableAmount?: number;

  constructor(data?: any) {
    if (!(data === undefined || data === null)) {
      this.initialAmount = data.initialAmount || 0;
      this.fiscalAttestationAmount = data.fiscalAttestationAmount || 0;
      this.fiscalAttestationFactor = data.fiscalAttestationFactor || 0;
      this.immovableAmount = data.immovableAmount || 0;
    }
  }
}
